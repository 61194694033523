.jwPlayerBottomSpacing {
	margin-bottom: var(--spacing-xl);
}

.jwPlayerContainerLoading {
	animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
	aspect-ratio: 16 / 9;
	background-color: var(--color-neutral-dark-60);
	width: 100%;
}

@keyframes pulse {

	0%,
	100% {
		opacity: 1;
	}

	50% {
		opacity: 0.5;
	}
}
